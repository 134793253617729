import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import ArticlesIndex from './ArticlesIndex'
import { SITE_TITLE } from '../constants'
import Layout from './layout'

const CategoryPage = ({
  pageContext: { category, categories },
  data: { allMarkdownRemark: { edges: articles } }
}) => (
  <Layout>
    <Helmet
      title={`${category} - Categories - ${SITE_TITLE}`}
      meta={[
        { name: 'description', content: '' },
        { name: 'keywords', content: categories }
      ]}
    />
    <ArticlesIndex
      selectedCategory={category}
      categories={categories}
      articles={articles}
    />
  </Layout>
)

export const query = graphql`
  query CategoryPageQuery($category: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...pageFields
        }
      }
    }
  }
`

export default CategoryPage
